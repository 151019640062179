<template>
  <div>
    <div class="vx-row justify-between mb-4">
      <div class="vx-col">
        <div class="page-title p-2">
          <div class="vx-col w-full flex items-center data-list-btn-container">
            <div :style="$route.query.tab === tab ? 'background: #1C5BFE; color: white' : ''" class="relative vx-row m-2 py-2 pl-4 pr-5 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
              v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab)">
              <div class="text-sm">{{$t(tab)}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col cursor-pointer flex self-center">
        <div @click="openExportModal" class="flex available-action px-4 py-2">
          <span class="material-icons text-lg self-center">upload_file</span>
          <span class="text-sm ml-2">{{ $t('Export') }}</span>
        </div>
      </div>
    </div>
    <master-bag-table :warehousesDic="warehousesDic" :query="activeTab == 0 ? 'to_warehouse' : 'from_warehouse'"/>
    <div>
      <shipblu-prompt
        class="shipment-modal"
        @close="closeModal"
        :active.sync="exportModal"
        :title="$t('Export')"
        :buttons-hidden="true">
          <div class="vx-row mb-4">
            <div class="vx-col w-1/2">
              <v-select name="criteria" v-model="type" :placeholder="$t('Criteria')" label="name" :options="types"/>
            </div>
            <div class="vx-col w-1/2">
              <v-select v-model="warehouse" :placeholder="$t('Warehouse')" label="name" :options="warehouses"/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <label class="font-semibold">{{$t('From')}}:</label>
              <datepicker :inline="true" v-validate="'required'" name="from" :disabledDates="disabledDatesStart" v-model="startDate" class="mt-1"></datepicker>
              <span class="text-danger text-sm" v-show="errors.has('from')">{{ errors.first('from') }}</span>
            </div>
            <div class="vx-col w-1/2">
              <label class="font-semibold">{{$t('To')}}:</label>
              <datepicker :inline="true" v-validate="'required'" name="to" :disabledDates="disabledDates" v-model="endDate" class="mt-1"></datepicker>
              <span class="text-danger text-sm" v-show="errors.has('to')">{{ errors.first('to') }}</span>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4 mt-4">
            <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
            <button @click="sendExport" class="active-btn btn">{{ $t('Send') }}</button>
          </div>
      </shipblu-prompt>
    </div>
  </div>
</template>

<script>
import MasterBagTable from './components/MasterBagsTab.vue'
import common  from '@/assets/utils/common'
import Datepicker from 'vuejs-datepicker'
import {sendRequest} from '../../http/axios/requestHelper.js'
import i18nData from '../../i18n/i18nData'
import vSelect from 'vue-select'
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'

export default {
  data () {
    return {
      disabledDates: {
        from: new Date()
      },
      disabledDatesStart: {
        from: new Date() // Disable all dates up to specific date
      },
      startDate: '',
      endDate: '',
      type: '',
      exportModal: false,
      types: [
        {
          name: 'Incoming',
          value: 'incoming'
        },
        {
          name: 'Outgoing',
          value: 'outgoing'
        }
      ],
      activeTab: 0,
      tabs: ['Incoming', 'Outgoing'],
      tabsDic: {'0': 'Incoming', '1': 'Outgoing'},
      tabsNameDic: {'Incoming': 0, 'Outgoing': 1},
      warehousesDic: {},
      warehouses: [],
      warehouse: ''
    }
  },
  watch: {
    '$route.query.tab' () {
      this.activeTab = this.tabsNameDic[this.$route.query.tab]
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: 1
        }
      }).catch(() => {})
    },
    activeTab () {
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.$route.query.page ? this.$route.query.page : 1
        }
      }).catch(() => {})
    },
    'startDate' () {
      const date = new Date(this.startDate)
      this.disabledDates.to = date
      const endDate = new Date()
      this.disabledDates.from = new Date(endDate.setDate(date.getDate() + 45))
    }
  },
  methods: {
    openExportModal () {
      this.exportModal = true
    },
    changeTabFilter (tab) {
      this.activeTab = this.tabsNameDic[tab]
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.activeTab,
          filter: this.filters,
          type: this.typeFilter
        }
      }).catch(() => {})
    },
    formatWarehouses (warehouses) {
      this.warehouses = warehouses
      this.warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.id] = warehouse.code
      })
      this.warehouses = this.warehouses.filter(warehouse => !warehouse.is_virtual)
    },
    sendExport () {
      this.$validator.validateAll().then(result => {
        if (result) {
          sendRequest(false, false, this, `api/v1/analytics/seals/export/?warehouse_code=${this.warehouse ? this.warehouse.code : ''}&criteria=${this.type ? this.type.value : ''}&from=${new Date(this.startDate).toISOString().split('T')[0]}&to=${new Date(this.endDate).toISOString().split('T')[0]}`, 'get', null, true, 
            (response) => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text: response.data.message,
                position: 'top-center'
              })
              this.closeModal()
            }
          )
        }
      })
    },
    closeModal () {
      this.$validator.pause()
      this.exportModal = false
      this.startDate = ''
      this.endDate = ''
      this.type = ''
      this.warehouse = ''
    }
  },
  created () {
    this.activeTab =  this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
    common.manageLoadWarehouses(this.formatWarehouses, this)
  },
  components: {
    MasterBagTable,
    Datepicker,
    vSelect,
    ShipbluPrompt
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>
