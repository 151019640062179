<template>
  <div>
    <shipblu-table
      :sst="true"
      orders
      pagination
      :max-items="maximumItems"
      search
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      :data="masterBags"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Seal Number')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Created By')}}</shipblu-th>
        <shipblu-th>{{$t('From')}}</shipblu-th>
        <shipblu-th>{{$t('To')}}</shipblu-th>
        <shipblu-th>{{$t('Packages In-Bag')}}</shipblu-th>
        <shipblu-th>{{$t('Bag Type')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
        :to="{ name: `${$store.state.AppActiveUser.userRole}-seal-view`, params: { sealId: data[indextr].id, warehouseID: $route.params.warehouseID }}">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3 pt-6">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>  
          <shipblu-td :data="data[indextr].code" :style="`color: ${getStyle(data[indextr])}`" class="inactive-link order-11">
            <div class="flex sm:static absolute gap-2 items-start justify-start transform-50" style="top:9px;" v-on:click.stop>
              <p class="text-black" v-on:click.stop>{{ data[indextr].code }}</p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].created">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Created On')}}</p>
            <span class="sm:text-base text-sm">{{ common.splitYearOfDate(data[indextr].created) }}</span>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].createdBy">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Created By')}}</p>
            <span class="sm:text-base text-sm">{{ data[indextr].user.first_name }} {{ data[indextr].user.last_name }}</span>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].fromWarehouse">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('From')}}</p>
            <span class="sm:text-base text-sm">{{ warehousesDic[data[indextr].bin.warehouse] }}</span>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].toWarehouse">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('To')}}</p>
            <span class="sm:text-base text-sm">{{ warehousesDic[data[indextr].bin.to_warehouse] }}</span>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].packages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Packages In-Bag')}}</p>
            <span class="sm:text-base text-sm">{{data[indextr].number_of_packages}}</span>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].bagType">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Bag Type')}}</p>
            <span class="sm:text-base text-sm">{{data[indextr].fragile ? 'Fragile' : 'Normal'}}</span>
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  props: ['query', 'warehousesDic'],
  data () {
    return {
      common,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      masterBags: [],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      searchVal: '',
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      offset: 0,
      seal: {},
      searchInProgress: false,
      tableLoader: false,
      searchedValue: ' '
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          page:this.currentPage
        }
      }).catch(() => {})
      this.loadSeals()
    },
    '$route.params.warehouseID' () {
      this.searchVal = ''
      this.loadSeals()
    },
    '$route.query.tab' () {
      this.loadSeals()
    }
  },
  methods: {
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search && search.target ? search.target.value : search
      return common.manageSearch(search, this.loadSearchBags)
    },
    loadSearchBags () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadSeals()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadSeals () {
      this.searchInProgress = true
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/seals/?${this.query}=${this.$route.params.warehouseID}&limit=${this.maximumItems}&offset=${this.offset}&search=${this.searchVal}`, 'get', null, true,
        (response) => {
          this.masterBags = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    sealView (seal) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-seal-view`,
        params: {
          warehouseID: this.$route.params.warehouseID,
          sealId: seal.id
        }
      }).catch(() => {})
    },
    confirmMissing (data) {
      this.seal = data
      this.$vs.dialog({
        color: 'warning',
        title: 'Confirm',
        text: 'Are you sure to update this seal with missing packages status?',
        accept: this.updateSealTracking
      })
    },
    updateSealTracking (seal, status) {
      const tracking = {
        seal: seal ? seal.id : this.seal.id,
        status: status ? status : 'missing_packages'
      }
      sendRequest(false, false, this, 'api/v1/tracking/seals/', 'post', tracking, true, 
        () => {
          this.loadSeals()
        }
      )
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadSeals()
  }
}
</script>

<style lang="scss">
.con-pagination-table{
  display: none;
}
</style>